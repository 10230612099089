import * as React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    Page,
    FlexBox,
    Panel,
    Title,
    Label,
    Input,
    ActionButton,
} from "@kortvaluta/admin-twooca-react";
import { FaCheckCircle } from "react-icons/fa";
import PageBackground from "../../shared/page/PageBackground";
import "./styles.scss";

interface PasswordResetViewProps {
    newPass: string;
    confirmPass: string;
    success: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    submit: (event: React.MouseEvent<HTMLElement>) => void;
}

const PasswordResetView: React.FC<PasswordResetViewProps> = (props) => {
    const { newPass, confirmPass, success, onChange, submit } = props;
    const navigate = useNavigate();

    useEffect(() => {
        if (success) navigate("/login");
    }, [success]);

    return (
        <PageBackground>
            <Page>
                <div className="password-reset">
                    <Panel>
                        <Title title="パスワードを再設定する" />
                        {success && (
                            <div className="password-reset__success">
                                <div className="password-reset__success__icon">
                                    <FaCheckCircle />
                                </div>
                                メールが正常に送信されました
                            </div>
                        )}
                        <form className="password-reset__form">
                            <div className="password-reset__form__group">
                                <Label
                                    required={true}
                                    bold={true}
                                    text="新しいのパスワード"
                                />
                                <Input
                                    placeholder="新しいのパスワード 半角英字、半角数字混在で８文字以上"
                                    autoComplete="current-password"
                                    onChange={onChange}
                                    value={newPass}
                                    type="password"
                                    name="new-password"
                                    onEnter={true}
                                />
                            </div>
                            <div className="password-reset__form__group">
                                <Label
                                    required={true}
                                    bold={true}
                                    text="新しいのパスワード（確認）"
                                />
                                <Input
                                    placeholder="新しいのパスワード（確認）"
                                    autoComplete="current-password"
                                    onChange={onChange}
                                    value={confirmPass}
                                    type="password"
                                    name="confirm-password"
                                    onEnter={true}
                                />
                            </div>
                            <div className="password-reset__form__group">
                                <FlexBox justify="center">
                                    <ActionButton
                                        text="更新する"
                                        color="primary"
                                        onClick={submit}
                                        submit={true}
                                    />
                                </FlexBox>
                            </div>
                        </form>
                    </Panel>
                </div>
            </Page>
        </PageBackground>
    );
};

export default PasswordResetView;
