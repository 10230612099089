import React, { useState } from "react";

import { Column, Label, Row } from "@kortvaluta/admin-twooca-react";

import Select from "../../../select/Select";
import { checkFormValue } from "../../helpers";

interface ISearchOptionInputProps {
  sleepTypeOption?: { text: string; value: string }[];
  setSelectedSleepType?: (value: string) => void;
  selectedSleepTypeValue?: number;
  setSelectedSleepTypeValue?: (value: number) => void;
}

const SearchOptionInput: React.FC<ISearchOptionInputProps> = ({
  sleepTypeOption,
  selectedSleepTypeValue,
  setSelectedSleepType,
  setSelectedSleepTypeValue,
}) => {
  const onChangeSleepType = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedIndex = Number(event.target.value);
    setSelectedSleepTypeValue(Number(sleepTypeOption[selectedIndex].value));
    setSelectedSleepType(sleepTypeOption[selectedIndex].text);
  };
  return (
    <div className="search-input">
      <Row>
        <Column xs={12} md={6}>
          <Label text="睡眠種別" />
        </Column>
        <Column xs={7} md={6}>
          <Select
            value={checkFormValue(selectedSleepTypeValue)}
            options={sleepTypeOption}
            onChange={onChangeSleepType}
          />
        </Column>
      </Row>
    </div>
  );
};

export default SearchOptionInput;
