import React from "react";
import { Column, Label, Row } from "@kortvaluta/admin-twooca-react";
import { SearchUserOption } from "../../../../store/healthcareSearch/models";

import Select from "react-select";

interface SearchSelectProps {
  options: SearchUserOption[];
  label?: string;
  placeholder: string;
  onChange: (user: SearchUserOption) => void
}

const SearchSelect: React.FC<SearchSelectProps> = (props) => {
  const {
    options,
    label,
    placeholder,
    onChange
  } = props;
  return (
    <Row>
      {label && (
        <Column xs={12} md={1}>
          <Label text={label} />
        </Column>
      )}
      <Column xs={12} md={12}>
        <Select
          className="basic-single"
          classNamePrefix="select"
          placeholder={placeholder}
          isClearable={true}
          isSearchable={true}
          name="name"
          options={options}
          onChange={onChange}
        />
      </Column>
    </Row>
  )
}

export default SearchSelect;
