import axios from "axios";
import { healthDataUrl } from "../../constants";
import {
	ISearchTarget,
	ISearchResultState,
	SearchTarget,
	SearchUserOption
} from "./models";

const getSearchData = async (
	token: string,
	target: ISearchTarget,
	params: {
		user: SearchUserOption,
		minDate: string,
		maxDate: string,
		minValue: string,
		maxValue: string,
		searchType: string,
		sleepType?: string
	},
	page: number,
): Promise<{ token: string; data: ISearchResultState}> => {
	try {
		const searchTarget = SearchTarget[target];
		const res = await axios.get(`${healthDataUrl}/search`, {
			headers: { Authorization: `${token}` },
			params: {
				user: params.user,
				searchTarget,
				params,
				page,
			}
		});
		return {
			token: res.headers.authorization,
			data: res.data.result
		};
	} catch (e) {
		throw e;
	}
};

export default getSearchData;
