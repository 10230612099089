export const formatCreatedAt = (dateStr: string) => {
    return new Date(dateStr).toLocaleString();
};

//formatter for month range for searching
export const formatMonthsParams = (month: number, year: number) => {
    const firstDay = new Date(year, month, 1).getDate();
    const lastDay = new Date(year, month + 1, 0).getDate();
    return {
        created_at_gteq: `${year}-${month}-${firstDay}`,
        created_at_lteq: `${year}-${month}-${lastDay}`,
    };
};

//formatter for time (ex 18:30:45)
export const getCurrentHours = () => {
    const today = new Date();
    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    const currentHours = `${hours}:${minutes}:${seconds}`;
    return currentHours;
};

//formatter for birthday (ex 1996/01/16 or 1996年1月16日)
export const dateFormatter = (dateStr: string, toJapanese?: boolean) => {
    const date = new Date(dateStr);
    if (date === null) return "";
    const str =
        date.getFullYear() +
        `${toJapanese ? "年" : "/"}` +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        `${toJapanese ? "月" : "/"}` +
        ("0" + date.getDate()).slice(-2) +
        `${toJapanese ? "日" : ""}`;
    return str;
};

export const getRetiredDate = (date: Date) => {
    return (
        date.getFullYear() +
        "/" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "/" +
        ("0" + date.getDate()).slice(-2)
    );
};
