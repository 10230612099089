// 検索ターゲットの型を定義
export type ISearchTarget = "心拍" | "体温" | "歩数" | "睡眠";

export enum SearchTarget {
  "心拍" = "heartRate",
  "体温" = "bodyTemperature",
  "歩数" = "stepCount",
  "睡眠" = "sleepSession",
}

export interface IInputRange {
  (
    heartRange: RangeDetail,
    averageHeartRange: RangeDetail,
    stepCountRange: RangeDetail,
    averageStepCountRange: RangeDetail,
    temperatureRange: RangeDetail,
    averageTemperatureRange: RangeDetail,
    sleepTimeRange: RangeDetail,
    averageSleepTimeRange: RangeDetail,
    sleepTypeValue: number,
  ): void;
}

// 検索パラメータの型を定義
export interface ISearchParams {
  user: SearchUserOption;
  dateRangeValue?: RangeDetail;
  heartRange: RangeDetail;
  averageHeartRange: RangeDetail;
  stepCountRange: RangeDetail;
  averageStepCountRange: RangeDetail;
  temperatureRange: RangeDetail;
  averageTemperatureRange: RangeDetail;
  sleepTimeRange: RangeDetail;
  averageSleepTimeRange: RangeDetail;
  sleepTypeValue: number;
}

// 検索アクションの型を定義
export interface ISearchAction {
  target: ISearchTarget;
  userOptions: SearchUserOption[];
  setSearchParams: React.Dispatch<React.SetStateAction<ISearchParams>>;
  handleSearch: (params?: ISearchParams, page?: number) => void;
}

// 検索レンジの型を定義
export interface SearchRange {
  heartRateRange?: RangeDetail;
  averageHeartRateRange?: RangeDetail;
  stepCountRange?: RangeDetail;
  averageStepCountRange?: RangeDetail;
  temperatureRange?: RangeDetail;
  averageTemperatureRange?: RangeDetail;
  sleepTimeRange?: RangeDetail;
  averageSleepTimeRange?: RangeDetail;
}

// レンジの詳細な型を定義
interface RangeDetail {
  start: string;
  end: string;
}

// 検索アクションタイプのenumを定義
export enum SearchActionTypes {
  SET_SEARCH_DATA = "healthcare/search_data_set",
}

// 検索ターゲットのenumを定義
export enum SearchTargetEnum {
  HEART_RATE = "heartRate",
  BODY_TEMPERATURE = "bodyTemperature",
  STEP_COUNT = "stepCount",
  SLEEP_SESSION = "sleepSession",
}

// ユーザーの型を定義
interface User {
  user_uid: string;
  name: string;
}

export interface UserHealthDatum extends User {
  date: string;
  value: number;
  avg?: string;
  sum?: string;
}

// 心拍データの型を定義
export interface HeartRate extends UserHealthDatum {
  avg: string;
}

// 体温データの型を定義
export interface BodyTemperature extends UserHealthDatum {
  avg: string;
}

// 歩数データの型を定義
export interface StepCount extends UserHealthDatum {
  sum: string;
}

// 睡眠データの型を定義
export interface SleepSession extends User {
  sleepType: string;
  date?: string;
  start_date?: string;
  end_date?: string;
  sleep_start?: string;
  sleep_end?: string;
  deep_sleep?: number;
  light_sleep?: number;
  clear_time?: number;
  sum: number;
  avg: string;
}

// 検索結果の型を定義
export interface ISearchResult {
  heartRate: {
    data: HeartRate[];
    total_pages: number;
    limit_value: number;
  };
  bodyTemperature: {
    data: BodyTemperature[];
    total_pages: number;
    limit_value: number;
  };
  stepCount: {
    data: StepCount[];
    total_pages: number;
    limit_value: number;
  };
  sleepSession: {
    data: SleepSession[];
    total_pages: number;
    limit_value: number;
  };
}

// 検索結果のアクションの型を定義
export interface ISearchResultSetAction {
  type: SearchActionTypes.SET_SEARCH_DATA;
  payload: ISearchResultState;
}

// 検索結果のステートの型を定義
export type ISearchResultState = ISearchResult;

// 初期の検索結果のステートを定義
export const initialHealthCare: ISearchResultState = {
  heartRate: {
    data: [],
    total_pages: 0,
    limit_value: 25,
  },
  bodyTemperature: {
    data: [],
    total_pages: 0,
    limit_value: 25,
  },
  stepCount: {
    data: [],
    total_pages: 0,
    limit_value: 25,
  },
  sleepSession: {
    data: [],
    total_pages: 0,
    limit_value: 25,
  },
};

export enum SearchUserOptionActionTypes {
  SET_SEARCH_USER_OPTIONS = "SET_SEARCH_USER_OPTIONS",
}

export interface SearchUserOptionSetAction {
  type: SearchUserOptionActionTypes.SET_SEARCH_USER_OPTIONS;
  payload: SearchUserOptionsState;
}
export interface SearchUserOption {
  value: string;
  label: string;
  name: string;
}

export type SearchUserOptionsState = SearchUserOption[];

export const initialSearchUserOptions: SearchUserOptionsState =
  [
    { value: "", label: "", name: "" }
  ]
