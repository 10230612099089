import * as React from "react";
import "./styles.scss";

interface Option {
  text: string;
  value: string;
}
interface SelectProps {
  options?: Array<Option>;
  value?: string;
  name?: string;
  hasError?: boolean;
  disabled?: boolean;
  onChange?: (event: React.FormEvent<HTMLSelectElement>) => void;
}

const Select: React.FC<SelectProps> = (props) => {
  const { options, value, name, hasError, disabled, onChange } = props;
  const currentValue = value || "DEFAULT";
  return (
    <div className="picker">
      <select
        className={`picker__select1${
          hasError ? " picker__select1--error" : ""
        }`}
        onChange={onChange}
        value={currentValue}
        disabled={disabled}
        name={name}
      >
        <option value="DEFAULT" disabled>
          選択
        </option>
        {options.map((item, index) => (
          <option
            key={index.toString()}
            className="picker__select1__option"
            value={item.value}
          >
            {item.text}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
