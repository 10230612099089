import React from "react";
import { DateRangePicker } from "rsuite";

import { Column, Label, Row } from "@kortvaluta/admin-twooca-react";
import "./styles.scss";

interface SearchDateInputProps {
  isDateRange?: boolean;
  isDisabled?: boolean;
  setDateRange: (value: any) => void;
}

const SearchDateInput: React.FC<SearchDateInputProps> = ({
  isDateRange,
  isDisabled,
  setDateRange,
}) => {
  const { combine, allowedMaxDays, afterToday } = DateRangePicker;
  const handleDateChange = (value) => {
    setDateRange(value);
  };
  const handleDateClean = () => {
    setDateRange(null);
  };
  return (
    <Row>
      <Column xs={12} md={1}>
        <Label text=" 期間" />
      </Column>
      <Column xs={12} md={12}>
        <div className={`date${isDateRange ? "" : "--empty"}`}>
          <DateRangePicker
            disabledDate={combine(allowedMaxDays(14), afterToday())}
            ranges={[]}
            placeholder={"日付を選択してください。"}
            locale={{
              sunday: "日",
              monday: "月",
              tuesday: "火",
              wednesday: "水",
              thursday: "木",
              friday: "金",
              saturday: "土",
              ok: "OK",
            }}
            showOneCalendar
            placement="auto"
            onOk={handleDateChange}
            disabled={isDisabled}
            onClean={handleDateClean}
          />
        </div>
      </Column>
    </Row>
  );
};

export default SearchDateInput;
