import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Page, Panel } from "@kortvaluta/admin-twooca-react";
import { RootState } from "../../../../store";
import { employeeSuccess } from "../../../../store/employee/actions";
import {
  BodyTemperature,
  HeartRate,
  ISearchParams,
  ISearchTarget,
  SleepSession,
  StepCount
} from "../../../../store/healthcareSearch/models";
import Content from "../../../shared/layout/content/Content";
import PageBackground from "../../../shared/page/PageBackground";
import SearchContainer from "../../../shared/searchContainer/SearchContainer";
import TabPanel from "../../../shared/tab/panel/TabPanel";
import HeaderTitle from "../../../shared/title/Title";
import {
  thunkGetSearchData,
  thunkGetSearchUsers
} from "../../../../store/healthcareSearch/thunk";
import ExhaustiveError from "../../../../helpers/exhaustiveError";
import DataTable from "./component/DataTable";

// 初期値を別の変数として定義
const initialCurrentPage: readonly number[] = [1, 1, 1, 1];
const initialTotalPage: readonly number[] = [0, 0, 0, 0];
const initialTableData: (HeartRate[] | BodyTemperature[] | StepCount[] | SleepSession[])[]
  = [[], [], [], []];

const HealthcareManagementScreen: React.FC = () => {
  // グローバルステートから必要な値を取得
  const isLoading = useSelector((store: RootState) => store.loadingBar.default === 1);
  const token = useSelector((store: RootState) => store.session.token);
  const sidebarToggle = useSelector((store: RootState) => store.sidebar.sidebarToggle);
  const updateSuccess = useSelector((store: RootState) => store.employeesInfo.success);
  const result = useSelector((store: RootState) => store.healthcareSearch);
  const userOptions = useSelector((store: RootState) => store.userOptions);

  // ローカルステートの初期値を変数で設定
  const [currentPage, setCurrentPage] = useState([...initialCurrentPage]);
  const [totalPage, setTotalPage] = useState([...initialTotalPage]);
  const [tableData, setTableData] = useState([...initialTableData]);
  const [success, setSuccess] = useState(false);
  const [activeIdx, setActiveIdx] = useState(0);
  const [searchParams, setSearchParams] = useState<ISearchParams>();

  const tabItems: ISearchTarget[] = ["心拍", "体温", "歩数", "睡眠"];

  // ディスパッチ関数を取得
  const dispatch = useDispatch();

  // 副作用の処理をuseEffectで分割
  useEffect(() => {
    if (updateSuccess) {
      setSuccess(true);
      dispatch(employeeSuccess(false));
    }
  }, [dispatch, updateSuccess]);

  useEffect(() => {
    setTableData([
      result.heartRate.data,
      result.bodyTemperature.data,
      result.stepCount.data,
      result.sleepSession.data,
    ]);
    setTotalPage([
      result.heartRate.total_pages,
      result.bodyTemperature.total_pages,
      result.stepCount.total_pages,
      result.sleepSession.total_pages,
    ]);
  }, [result]);

  useEffect(() => {
    dispatch(thunkGetSearchUsers(token))
  }, [])

  // ページ変更時の処理を関数に抽出
  const handlePageChange = (page: number) => {
    setCurrentPage((prev) => {
      const newPage = [...prev];
      newPage[activeIdx] = page;
      return newPage;
    });
    handleSearch(searchParams, page);
    window.scrollTo(0, 0);
  };

// 検索パラメータを取得する関数を抽出
const getSearchParams = (inputValue: ISearchParams) => {
  const {
    user,
    dateRangeValue,
    averageHeartRange,
    heartRange,
    averageTemperatureRange,
    temperatureRange,
    averageStepCountRange,
    stepCountRange,
    sleepTypeValue,
    sleepTimeRange,
    averageSleepTimeRange
  } = inputValue;

  const minDate = dateRangeValue?.start || null;
  const maxDate = dateRangeValue?.end || null;

  const getParams = (minValue: string, maxValue: string, searchType: string) => ({
    user,
    minDate,
    maxDate,
    minValue,
    maxValue,
    searchType,
  });

  const target = tabItems[activeIdx];

  switch (target) {
    case "心拍":
      return averageHeartRange.start || averageHeartRange.end
        ? getParams(averageHeartRange.start, averageHeartRange.end, "avg")
        : getParams(heartRange.start, heartRange.end, "range");
    case "体温":
      return averageTemperatureRange.start || averageTemperatureRange.end
        ? getParams(averageTemperatureRange.start, averageTemperatureRange.end, "avg")
        : getParams(temperatureRange.start, temperatureRange.end, "range");
    case "歩数":
      return averageStepCountRange.start || averageStepCountRange.end
        ? getParams(averageStepCountRange.start, averageStepCountRange.end, "sum")
        : getParams(stepCountRange.start, stepCountRange.end, "range");
    case "睡眠":
      if (sleepTypeValue > 0) {
        return {
          ...getParams(sleepTimeRange.start, sleepTimeRange.end, "range"),
          selectedSleepTypeValue: sleepTypeValue,
        };
      }
      return averageSleepTimeRange.start || averageSleepTimeRange.end
        ? getParams(averageSleepTimeRange.start, averageSleepTimeRange.end, "avg")
        : getParams(sleepTimeRange.start, sleepTimeRange.end, "range");
    default:
      throw new ExhaustiveError(target)
  }
};

  // 検索処理を関数に抽出
  const handleSearch = (inputValue: ISearchParams, page?: number) => {
    if (!page) {
      setCurrentPage((prev) => {
        const newPage = [...prev];
        newPage[activeIdx] = 1;
        return newPage;
      });
      page = 1
    }

    const params = getSearchParams(inputValue);
    if (params) {
      dispatch(thunkGetSearchData(token, tabItems[activeIdx], params, page));
    }
  };

  return (
    <PageBackground>
      <Page toggle={sidebarToggle}>
        <Content>
          <SearchContainer
            target={tabItems[activeIdx]}
            setSearchParams={setSearchParams}
            handleSearch={handleSearch}
            userOptions={userOptions}
          />
          <TabPanel items={tabItems} activeIdx={activeIdx} onClick={setActiveIdx}>
            <Panel>
              <HeaderTitle title="会員リスト一覧" />
              {tableData[activeIdx].length === 0 ? (
                <div className="emptyTable">No result found</div>
              ) : (
                <DataTable
                  data={tableData[activeIdx]}
                  showDataType={tabItems[activeIdx]}
                  totalPages={totalPage[activeIdx]}
                  currentPage={currentPage[activeIdx]}
                  onPageChange={handlePageChange}
                />
              )}
            </Panel>
          </TabPanel>
        </Content>
      </Page>
    </PageBackground>
  );
};

export default HealthcareManagementScreen;
