import React from "react";
import { Table, TableBody, TableHead, Td, Th, Tr } from "@kortvaluta/admin-twooca-react";
import ExhaustiveError from "../../../../../helpers/exhaustiveError";
import Paginate from "../../../../shared/pagination/Paginate";
import { toDate, toDateTime, toTime } from "../../../../../utils/dateUtils";
import {
  ISearchTarget,
  UserHealthDatum,
  HeartRate,
  BodyTemperature,
  StepCount,
  SleepSession
} from "../../../../../store/healthcareSearch/models";

// テーブルのプロパティの型を定義
interface DataTableProps {
  data: HeartRate[] | BodyTemperature[] | StepCount[] | SleepSession[];
  showDataType: ISearchTarget;
  totalPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}

const DataTable: React.FC<DataTableProps> = ({
  data,
  showDataType,
  totalPages,
  currentPage,
  onPageChange,
}) => {
  // 睡眠時間を取得する関数
  const getSleepTime = (session: SleepSession): string => {
    const { deep_sleep, light_sleep, clear_time, sum } = session;
    const sleepTime = deep_sleep || light_sleep || clear_time || sum || 0;
    return toTime(sleepTime);
  };

  // 睡眠タイプを取得する関数
  const getSleepType = (session: SleepSession): string => {
    const { deep_sleep, light_sleep, clear_time } = session;
    if (deep_sleep) return "深い睡眠";
    if (light_sleep) return "浅い睡眠";
    if (clear_time) return "睡眠時覚醒";
    return "";
  };

  // 数値を指定の小数点以下の桁数で丸める関数
  const roundValue = (value?: string, decimalPlaces = 2): string => {
    if (!value) return null;
    return parseFloat(value).toFixed(decimalPlaces);
  };

  // テーブルのヘッダーを取得する関数
  const getTableHeader = (): JSX.Element => {
    switch (showDataType) {
      case "心拍":
        return (
          <Tr>
            <Th>氏名</Th>
            <Th>日付</Th>
            <Th>心拍数</Th>
            <Th>平均心拍数</Th>
          </Tr>
        );
      case "体温":
        return (
          <Tr>
            <Th>氏名</Th>
            <Th>日付</Th>
            <Th>体温 (度)</Th>
            <Th>平均体温 (度)</Th>
          </Tr>
        );
      case "歩数":
        return (
          <Tr>
            <Th>氏名</Th>
            <Th>日付</Th>
            <Th>歩数</Th>
            <Th>累計歩数</Th>
          </Tr>
        );
      case "睡眠":
        return (
          <Tr>
            <Th>氏名</Th>
            <Th>日付</Th>
            <Th>入眠時間</Th>
            <Th>起床時間</Th>
            <Th>睡眠タイプ</Th>
            <Th>睡眠時間</Th>
            <Th>平均睡眠時間</Th>
          </Tr>
        );
      default:
        throw new ExhaustiveError(showDataType);
    }
  };

  const TableBodyItems: React.FC<{ data: UserHealthDatum[]; hasAvg?: boolean; hasSum?: boolean }> = ({
    data,
    hasAvg = false,
    hasSum = false,
  }) => (
    <>
      {data.map((item, index) => (
        <Tr key={index}>
          <Td>{item.name}</Td>
          <Td>{(hasAvg && item.avg) || (hasSum && item.sum) ? toDate(item.date) : toDateTime(item.date)}</Td>
          <Td>{item.value}</Td>
          {hasAvg && <Td>{roundValue(item.avg)}</Td>}
          {hasSum && <Td>{item.sum}</Td>}
        </Tr>
      ))}
    </>
  );

  const SleepSessionTableBody: React.FC<{ data: SleepSession[] }> = ({ data }) => (
    <>
      {data.map((item: SleepSession, index: number) => (
        <Tr key={index}>
          <Td>{item.name}</Td>
          <Td>{toDate(item.sleep_end)}</Td>
          <Td>{toDateTime(item.sleep_start || item.start_date)}</Td>
          <Td>{toDateTime(item.sleep_end || item.end_date)}</Td>
          <Td>{getSleepType(item)}</Td>
          <Td>{getSleepTime(item)}</Td>
          <Td>{roundValue(item.avg)}</Td>
        </Tr>
      ))}
    </>
  );

  // テーブルのボディを取得する関数
  const getTableBody = (): JSX.Element => {
    switch (showDataType) {
      case "心拍": return (
        <TableBodyItems data={data as HeartRate[]} hasAvg />
      );
      case "体温": return (
        <TableBodyItems data={data as BodyTemperature[]} hasAvg />
      );
      case "歩数": return (
        <TableBodyItems data={data as StepCount[]} hasSum/>
      );
      case "睡眠": return (
        <SleepSessionTableBody data={data as SleepSession[]} />
      );
      default:
        throw new ExhaustiveError(showDataType);
    }
  };

  return (
    <>
      <div className="table-scroll">
        <Table>
          <TableHead>{getTableHeader()}</TableHead>
          <TableBody>{getTableBody()}</TableBody>
        </Table>
      </div>
      {totalPages > 1 && (
        <Paginate
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={onPageChange}
        />
      )}
    </>
  );
};

export default DataTable;
