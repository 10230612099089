import * as Models from "./models";

export function healthcareReducer(
    state = Models.initialHealthCare,
    action:
        | Models.IHealthDataDashboardSetAction
        | Models.IRingUserSetAction
): Models.IHealthcareDashboardState & Models.IRingUserState {
    switch (action.type) {
        case Models.HealthcareActionTypes.HEALTHCARE_SET:
            return {
                ...state,
                ...action.payload,
            };
        case Models.HealthcareActionTypes.RING_USER_SET:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
}
