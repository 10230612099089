import {
    Page,
    Paginate,
    Panel,
    Table,
    TableBody,
    TableHead,
    Td,
    Th,
    Tr,
} from "@kortvaluta/admin-twooca-react";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatMonthsParams } from "../../../helpers/dateFormatter";
import useSetActiveId from "../../../hooks/useSetActiveId";
import { RootState } from "../../../store/index";
import {
    IDateParams,
    IMonthlyParams,
    initialDateParams,
} from "../../../store/transactions/models";
import { ICoupon, IUsageList } from "../../../store/usage/models";
import { thunkGetUsageList } from "../../../store/usage/thunk";
import Amount from "../../shared/amount/Amount";
import ClearButton from "../../shared/button/clear/ClearButton";
import Monthly from "../../shared/monthly/Monthly";
import PageBackground from "../../shared/page/PageBackground";
import { compare } from "../history/helpers";
import "./styles.scss";

interface ISortToggle {
    category: boolean;
    description: boolean;
    brand: boolean;
    total_items_used: boolean;
    total_amount_used: boolean;
}

type TSortCategories =
    | "category"
    | "description"
    | "brand"
    | "total_items_used"
    | "total_amount_used";

const UsageScreen: React.FC = () => {
    //global state
    const token: string = useSelector(
        (store: RootState) => store.session.token
    );
    const sidebarToggle: boolean = useSelector(
        (store: RootState) => store.sidebar.sidebarToggle
    );
    const usage: IUsageList = useSelector(
        (store: RootState) => store.usage.list
    );

    const totalPages: number = useSelector(
        (store: RootState) => store.usage.list.coupons.total_pages
    );

    //local state
    const [month, setMonth] = useState(new Date().getMonth() + 1);
    const [year, setYear] = useState(new Date().getFullYear());
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [dateParams, setDateParams] =
        useState<IDateParams>(initialDateParams);
    const [tableData, setTableData] = useState<Array<ICoupon>>([]);
    const [sortToggle, setSortToggle] = useState<ISortToggle>({
        category: false,
        description: false,
        brand: false,
        total_items_used: false,
        total_amount_used: false,
    });

    //dispatch
    const dispatch = useDispatch();

    const getUsageList = useCallback((page: number, months: IMonthlyParams) => {
        dispatch(thunkGetUsageList(token, page, months));
    }, []);

    //useEffect
    useSetActiveId(3);

    useEffect(() => {
        const monthlyParams: IMonthlyParams = formatMonthsParams(month, year);
        dispatch(thunkGetUsageList(token, currentPage, monthlyParams));
    }, []);

    useEffect(() => {
        setTableData(usage.coupons.usage_list);
    }, [usage]);

    //method
    const handleSetDateRange = (monthNum: number, yearNum: number) => {
        setMonth(monthNum);
        setYear(yearNum);
        const monthlyParams: IMonthlyParams = formatMonthsParams(
            monthNum,
            yearNum
        );
        setDateParams({ month, year });
        dispatch(thunkGetUsageList(token, currentPage, monthlyParams));
    };

    const resetFiltering = () => {
        setCurrentPage(1);
        setDateParams(initialDateParams);
        getUsageList(
            1,
            formatMonthsParams(initialDateParams.month, initialDateParams.year)
        );
    };

    const sortData = (type: TSortCategories): void => {
        setTableData(
            tableData.sort((a, b) => {
                return compare(a, b, sortToggle[type], type);
            })
        );
        setSortToggle({
            ...sortToggle,
            [type]: !sortToggle[type],
        });
    };

    const setPage = (page: number) => {
        setCurrentPage(page);
        getUsageList(
            page,
            formatMonthsParams(initialDateParams.month, initialDateParams.year)
        );
    };

    if (!usage) return null;
    return (
        <PageBackground>
            <Page toggle={sidebarToggle}>
                <div className="usage">
                    <div className="usage__filter">
                        <div className="usage__filter__date">
                            <Monthly
                                month={month}
                                year={year}
                                setDateRange={handleSetDateRange}
                            />
                        </div>
                    </div>
                    <Panel>
                        <div className="usage__clear-filter">
                            <ClearButton onClick={resetFiltering} />
                        </div>
                        <Table title="利用明細">
                            <TableHead>
                                <Tr>
                                    <Th
                                        sortable={true}
                                        onSort={() => {
                                            sortData("category");
                                        }}
                                        toggle={sortToggle.category}
                                    >
                                        カテゴリー
                                    </Th>
                                    <Th
                                        sortable={true}
                                        onSort={() => {
                                            sortData("description");
                                        }}
                                        toggle={sortToggle.description}
                                    >
                                        ブランド
                                    </Th>
                                    <Th
                                        sortable={true}
                                        onSort={() => {
                                            sortData("brand");
                                        }}
                                        toggle={sortToggle.brand}
                                    >
                                        詳細
                                    </Th>
                                    <Th
                                        sortable={true}
                                        onSort={() => {
                                            sortData("total_items_used");
                                        }}
                                        toggle={sortToggle.total_items_used}
                                    >
                                        利用件数
                                    </Th>
                                    <Th
                                        sortable={true}
                                        onSort={() => {
                                            sortData("total_amount_used");
                                        }}
                                        toggle={sortToggle.total_amount_used}
                                    >
                                        利用 pt
                                    </Th>
                                </Tr>
                            </TableHead>
                            <TableBody>
                                {Array.isArray(tableData) &&
                                    tableData.map((item, index) => (
                                        <Tr key={index.toString()}>
                                            <Td>{item.category}</Td>
                                            <Td>{item.description}</Td>
                                            <Td>{item.brand}</Td>
                                            <Td>{item.total_items_used}</Td>
                                            <Td>
                                                <Amount
                                                    value={
                                                        -(item.total_amount_used)
                                                    }
                                                />
                                            </Td>
                                        </Tr>
                                    ))}
                            </TableBody>
                        </Table>
                        {totalPages > 1 && (
                            <div>
                                <Paginate
                                    totalPages={totalPages}
                                    currentPage={currentPage}
                                    onPageChange={setPage}
                                />
                            </div>
                        )}
                    </Panel>
                </div>
            </Page>
        </PageBackground>
    );
};

export default UsageScreen;
