import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { navigationActive } from "../store/navigation/actions";

export default function useSetActiveId(id: number): void {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(navigationActive({ id }));
    }, [navigationActive]);
}
