import * as React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { RootState } from "../../../store";
import { thunkLogin } from "../../../store/session/thunk";
import {
    ActionButton,
    Container,
    FlexBox,
    Input,
} from "@kortvaluta/admin-twooca-react";
import SpaceBox from "../../shared/box/space/SpaceBox";
import BrandLogo from "../../shared/logo/BrandLogo";
import ErrorText from "../../shared/error/text/ErrorText";

const LoginScreen: React.FC = () => {
    //glocal state
    const status: boolean = useSelector(
        (store: RootState) => store.session.status
    );

    // clear localstorage
    const savedCameraMode = localStorage.getItem('cameraMode');
    localStorage.clear();
    if (savedCameraMode) {
        localStorage.setItem('cameraMode', savedCameraMode);
    }

    //navigate
    const navigate = useNavigate();

    //dispatch
    const dispatch = useDispatch();

    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    const [emailEmpty, setEmailEmpty] = useState<boolean>(false);
    const [passwordEmpty, setPasswordEmpty] = useState<boolean>(false);

    const submit = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        if (!email) setEmailEmpty(true);
        if (!password) setPasswordEmpty(true);
        if (email && password) dispatch(thunkLogin(email, password));
    };

    useEffect(() => {
        if (status) navigate("/");
    }, [status]);

    return (
        <Container>
            <div
                style={{
                    minHeight: "100vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <div style={{ width: "min(50rem,100%)" }}>
                    <FlexBox direction="column" justify="center">
                        <FlexBox justify="center">
                            <BrandLogo
                                isWhite={false}
                                styles="login__form__brand"
                            />
                        </FlexBox>
                        {passwordEmpty && (
                            <ErrorText text="パスワードが違います。" />
                        )}
                        {emailEmpty && (
                            <ErrorText text="メールアドレスが違います。" />
                        )}
                        <SpaceBox my="md">
                            <FlexBox justify="center">
                                <Input
                                    placeholder="メールアドレス"
                                    autoComplete="username"
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        setEmail(event.target.value);
                                        if (event.target.value)
                                            setEmailEmpty(false);
                                    }}
                                    value={email}
                                    type="email"
                                    name="email"
                                    pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                                    onEnter={true}
                                    hasError={emailEmpty}
                                />
                            </FlexBox>
                        </SpaceBox>
                        <SpaceBox my="md">
                            <FlexBox justify="center">
                                <Input
                                    placeholder="パスワード"
                                    autoComplete="current-password"
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        setPassword(event.target.value);
                                        if (event.target.value)
                                            setPasswordEmpty(false);
                                    }}
                                    value={password}
                                    type="password"
                                    name="password"
                                    onEnter={true}
                                    hasError={passwordEmpty}
                                />
                            </FlexBox>
                        </SpaceBox>
                        <SpaceBox my="md">
                            <FlexBox justify="center">
                                <ActionButton
                                    text="ログイン"
                                    color="primary"
                                    onClick={submit}
                                    submit={true}
                                />
                            </FlexBox>
                        </SpaceBox>
                        <SpaceBox my="md">
                            <FlexBox justify="center">
                                <Link
                                    to="/password-request"
                                    className="login__form__group__link"
                                >
                                    パスワードを忘れた方はこちら
                                </Link>
                            </FlexBox>
                        </SpaceBox>
                    </FlexBox>
                </div>
            </div>
        </Container>
    );
};

export default LoginScreen;
