import { hideLoading, showLoading } from "react-redux-loading-bar";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { axiosError } from "../../helpers/axiosError";
import {
    errorReset,
    errorSet,
} from "../error/actions";
import { RootState } from "../index";
import { sessionStatus } from "../session/actions";
import { searchDataSet, searchUserOptionsSet } from "./actions";
import { ISearchTarget, SearchUserOption } from "./models"
import getSerchData from "./getSerchData";
import getSearchUsers from "./getSearchUsers";

export const thunkGetSearchData =
    (
        token: string,
        target: ISearchTarget,
        params: {
            user: SearchUserOption,
            minDate: string,
            maxDate: string,
            minValue: string,
            maxValue: string,
            searchType: string,
            sleepType?: string
        },
        page = 1,
    ): ThunkAction<void, RootState, null, AnyAction> =>
    async (dispatch) => {
        // show loading bar
        dispatch(showLoading());
        //error reset
        dispatch(errorReset());
        try {
            const res = await getSerchData(token, target, params, page);
            dispatch(searchDataSet(res.data));
            dispatch(
                sessionStatus({
                    status: true,
                    token: res.token,
                })
            );
        } catch (e) {
            dispatch(errorSet(axiosError(e)));
        } finally {
            dispatch(hideLoading());
        }
    }

export const thunkGetSearchUsers =
    (
        token: string
    ): ThunkAction<void, RootState, null, AnyAction> =>
    async (dispatch) => {
        // show loading bar
        dispatch(showLoading());
        //error reset
        dispatch(errorReset());
        try {
            const res = await getSearchUsers(token);
            dispatch(searchUserOptionsSet(res.data));
            dispatch(
                sessionStatus({
                    status: true,
                    token: res.token,
                })
            );
        } catch (e) {
            dispatch(errorSet(axiosError(e)));
        } finally {
            dispatch(hideLoading());
        }
    }
