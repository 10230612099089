import { searchInputData } from "./SearchContainer.Hooks";

export const checkFormValue = (value: any) => {
  if (value === null || typeof value === "undefined") {
    return "";
  }
  return value;
};

export const checkInput = (
  startInput,
  endInput,
  startInputCheck,
  endInputCheck
) => {
  const macPattern = new RegExp("^[0-9]*\\.?[0-9]+$");
  const isStartInputValid = startInput === "" || macPattern.test(startInput);
  const isEndInputValid = endInput === "" || macPattern.test(endInput);
  if (
    startInput !== "" &&
    endInput !== "" &&
    (!isStartInputValid || !isEndInputValid)
  ) {
    startInputCheck = isStartInputValid;
    endInputCheck = isEndInputValid;
  } else if (startInput !== "" && !isStartInputValid) {
    startInputCheck = false;
    endInputCheck = true;
  } else if (endInput !== "" && (!isEndInputValid || endInput < startInput)) {
    startInputCheck = true;
    endInputCheck = false;
  } else {
    startInputCheck = true;
    endInputCheck = true;
  }
  return { startInputCheck, endInputCheck };
};

export function updateSearchInputData(range, startInputKey, endInputKey) {
  const { startInputCheck, endInputCheck } = checkInput(
    range.start,
    range.end,
    searchInputData[startInputKey],
    searchInputData[endInputKey]
  );
  searchInputData[startInputKey] = startInputCheck;
  searchInputData[endInputKey] = endInputCheck;
}
