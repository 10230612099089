import * as React from "react";

import { Column, Row } from "@kortvaluta/admin-twooca-react";

import SubTitle from "../../title/subTitle/SubTitle";
import SearchInput from "./searchInput/SearchInput";
import SearchOptionInput from "./searchOptionInput/SearchOptionInput";
import "./styles.scss";

interface ISearchBoxProps {
  title: string;
  label: string;
  labelAverage: string;
  sleepTypeOption?: { text: string; value: string }[];
  rangeValue?: { start: string; end: string };
  setRangeValue?: (value: { start: string; end: string }) => void;
  rangeAverageValue?: { start: string; end: string };
  setRangeAverageValue?: (value: { start: string; end: string }) => void;
  setSelectedSleepType?: (value: string) => void;
  selectedSleepTypeValue?: number;
  setSelectedSleepTypeValue?: (value: number) => void;
  maxLength: number;
  styleStart?: boolean;
  styleEnd?: boolean;
  styleStartAverage?: boolean;
  styleEndAverage?: boolean;
  searchTypeSelected?: string;
}

const SearchBox: React.FC<ISearchBoxProps> = ({
  title,
  label,
  labelAverage,
  sleepTypeOption,
  rangeValue,
  rangeAverageValue,
  selectedSleepTypeValue,
  setRangeValue,
  setRangeAverageValue,
  setSelectedSleepType,
  setSelectedSleepTypeValue,
  maxLength,
  styleStart,
  styleEnd,
  styleStartAverage,
  styleEndAverage,
}) => {
  return (
    <Row>
      <div className="search-box">
        <Column sm={12}>
          <SubTitle title={title} />
          {sleepTypeOption && (
            <SearchOptionInput
              sleepTypeOption={sleepTypeOption}
              setSelectedSleepType={setSelectedSleepType}
              setSelectedSleepTypeValue={setSelectedSleepTypeValue}
              selectedSleepTypeValue={selectedSleepTypeValue}
            />
          )}
        </Column>
        <Column sm={12}>
          <SearchInput
            label={label}
            maxLength={maxLength}
            rangeValue={rangeValue}
            setRangeValue={setRangeValue}
            styleStart={styleStart}
            styleEnd={styleEnd}
            isDisabled={
              rangeAverageValue.start !== "" || rangeAverageValue.end !== ""
            }
          />
        </Column>
        <Column sm={12}>
          <SearchInput
            label={labelAverage}
            maxLength={maxLength}
            rangeValue={rangeAverageValue}
            setRangeValue={setRangeAverageValue}
            styleStart={styleStartAverage}
            styleEnd={styleEndAverage}
            isDisabled={rangeValue.start !== "" || rangeValue.end !== ""}
          />
        </Column>
      </div>
    </Row>
  );
};

export default SearchBox;
