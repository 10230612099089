import { Page, Panel, Title } from "@kortvaluta/admin-twooca-react";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useSetActiveId from "../../../hooks/useSetActiveId";
import { RootState } from "../../../store";
import { thunkLogout } from "../../../store/session/thunk";
import Content from "../../shared/layout/content/Content";
import PageBackground from "../../shared/page/PageBackground";
import "./styles.scss";

const SettingScreen: React.FC = () => {
    //global state
    const token: string = useSelector(
        (store: RootState) => store.session.token
    );
    const sidebarToggle: boolean = useSelector(
        (store: RootState) => store.sidebar.sidebarToggle
    );

    //dispatch
    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(thunkLogout(token));
    };

    //useEffect
    useSetActiveId(9);

    return (
        <PageBackground>
            <Page toggle={sidebarToggle}>
                <Content>
                    <Panel>
                        <Title title="設定" />
                        <ul className="settings__list">
                            <li className="settings__list__item">
                                <a
                                    className="settings__list__item__link"
                                    href={`${process.env.SUPPORT_URL}/news`}
                                    target="_blank"
                                    rel="noopener"
                                >
                                    お知らせ
                                </a>
                            </li>
                            <li className="settings__list__item">
                                <a
                                    className="settings__list__item__link"
                                    href={`${process.env.CORPORATE_SITE_URL}/kortvaluta-terms.pdf`}
                                    target="_blank"
                                    rel="noopener"
                                >
                                    利用規約
                                </a>
                            </li>
                            <li className="settings__list__item">
                                <a
                                    className="settings__list__item__link"
                                    href={`${process.env.CORPORATE_SITE_URL}/privacy-policy`}
                                    target="_blank"
                                    rel="noopener"
                                >
                                    プライバシーポリシー
                                </a>
                            </li>
                            <li className="settings__list__item">
                                <a
                                    className="settings__list__item__link"
                                    href={`${process.env.CORPORATE_SITE_URL}/profile`}
                                    target="_blank"
                                    rel="noopener"
                                >
                                    運営会社
                                </a>
                            </li>
                            <li className="settings__list__item">
                                <a
                                    className="settings__list__item__link"
                                    href={`${process.env.SUPPORT_URL}/contact`}
                                    target="_blank"
                                    rel="noopener"
                                >
                                    お問い合わせ
                                </a>
                            </li>
                            <li className="settings__list__item">
                                <Link
                                    className="settings__list__item__link"
                                    to="/password-request"
                                >
                                    パスワードを再設定する
                                </Link>
                            </li>
                            <li className="settings__list__item">
                                <div
                                    className="settings__list__item__link"
                                    onClick={handleLogout}
                                >
                                    ログアウト
                                </div>
                            </li>
                        </ul>
                    </Panel>
                </Content>
            </Page>
        </PageBackground>
    );
};

export default SettingScreen;
