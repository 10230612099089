import * as React from "react";

import { Column, Input, Label, Row } from "@kortvaluta/admin-twooca-react";
import RadioGroup from "../../../radio/group/RadioGroup";
import RadioItem from "../../../radio/item/RadioItem";
import "./styles.scss";

interface ISearchInputProps {
  label: string;
  maxLength?: number;
  rangeValue: { start: string; end: string };
  setRangeValue: (value: { start: string; end: string }) => void;
  styleStart?: boolean;
  styleEnd?: boolean;
  isDisabled?: boolean;
}

const SearchInput: React.FC<ISearchInputProps> = ({
  label,
  setRangeValue,
  rangeValue,
  styleStart,
  styleEnd,
  isDisabled,
}) => {
  return (
    <div className="search-input">
      <Row>
        <Column xs={12} sm={6} md={6}>
          <Label text={label} />
        </Column>
        <Column xs={3} sm={2} md={2}>
          <Input
            type="text"
            hasError={!styleStart}
            value={rangeValue.start}
            onChange={(e) =>
              setRangeValue({
                ...rangeValue,
                [e.target.name]: e.target.value,
              })
            }
            disabled={isDisabled}
            name="start"
          />
        </Column>
        <Column xs={1} sm={1} md={1}>
          <Label text="〜" />
        </Column>
        <Column xs={3} sm={2} md={2}>
          <Input
            type="text"
            hasError={!styleEnd}
            value={rangeValue.end}
            onChange={(e) =>
              setRangeValue({
                ...rangeValue,
                [e.target.name]: e.target.value,
              })
            }
            disabled={isDisabled}
            name="end"
          />
        </Column>
      </Row>
    </div>
  );
};

export default SearchInput;
