export interface ISearchOptions {
  text: string;
  value: string;
}

export const searchSleepType: Array<ISearchOptions> = [
  {
    text: "---",
    value: "0",
  },
  {
    text: "全て",
    value: "1",
  },
  {
    text: "浅い睡眠",
    value: "2",
  },
  {
    text: "深い睡眠",
    value: "3",
  },
  {
    text: "睡眠中覚醒",
    value: "4",
  },
];
