import React, { useState } from "react";
import { Column, Panel, Row } from "@kortvaluta/admin-twooca-react";
import { toDate } from "../../../utils/dateUtils";
import { ISearchAction, SearchUserOption } from "../../../store/healthcareSearch/models";
import { searchSleepType } from "../../screens/healthcare/management/helpers";
import ActionBtn from "../actionButton/ActionBtn";
import SearchBox from "./searchBox/SearchBox";
import SearchDateInput from "./searchDateInput/SearchDateInput";
import SearchSelect from "./searchSelect"
import "./styles.scss";

// 初期値を別の変数として定義
const initialHeartRange = {
  heartRateRange: { start: "", end: "" },
  averageHeartRateRange: { start: "", end: "" },
};
const initialStepRange = {
  stepCountRange: { start: "", end: "" },
  averageStepCountRange: { start: "", end: "" },
};
const initialTemperatureRange = {
  temperatureRange: { start: "", end: "" },
  averageTemperatureRange: { start: "", end: "" },
};
const initialSleepRange = {
  sleepTimeRange: { start: "", end: "" },
  averageSleepTimeRange: { start: "", end: "" },
};
const initialDateRangeValue = { start: "", end: "" };
const initialSearchInput = {
  startHeartInput: true,
  endHeartInput: true,
  startAverageHeartInput: true,
  endAverageHeartInput: true,
  startStepsInput: true,
  endStepsInput: true,
  startAverageStepsInput: true,
  endAverageStepsInput: true,
  startTemperatureInput: true,
  endTemperatureInput: true,
  startAverageTemperatureInput: true,
  endAverageTemperatureInput: true,
  startSleepInput: true,
  endSleepInput: true,
  startAverageSleepInput: true,
  endAverageSleepInput: true,
};
const initialSearchUser = {
  label: "",
  value: "",
  name: "",
}

const SearchContainer: React.FC<ISearchAction> = ({
  target,
  userOptions,
  setSearchParams,
  handleSearch,
}) => {
  // useStateの初期値を変数で設定
  const [heartRange, setHeartRange] = useState(initialHeartRange);
  const [stepRange, setStepRange] = useState(initialStepRange);
  const [temperatureRange, setTemperatureRange] = useState(initialTemperatureRange);
  const [sleepRange, setSleepRange] = useState(initialSleepRange);
  const [dateRangeValue, setDateRangeValue] = useState(initialDateRangeValue);
  const [sleepType, setSleepType] = useState("");
  const [searchInput, setSearchInput] = useState(initialSearchInput);
  const [selectedSleepTypeValue, setSelectedSleepTypeValue] = useState(0);
  const [user, setUser] = useState<SearchUserOption>(initialSearchUser);
  const [isDateValue, setIsDateValue] = useState(true);

  const handleSelectedSleepType = (value: string) => {
    setSleepType(value);
  };

  const handleUserNameChange = (user?: SearchUserOption) => {
    if (user) {
      setUser(user);
    } else {
      setUser(initialSearchUser);
    }
  }

  // validateInputs関数を抽出
  const validateInputs = () => {
    const isDateRangeEmpty =
      dateRangeValue.start === "" && dateRangeValue.end === "";
    const checkRange = (range: any, key: string) => {
      if (key in range) {
        const { start, end } = range[key];
        const { start: avgStart, end: avgEnd } =
          range[`average${key.charAt(0).toUpperCase() + key.slice(1)}`];
        return start !== "" || end !== "" || avgStart !== "" || avgEnd !== "";
      }
      return false;
    };

    const isRangeEmpty = [
      heartRange,
      stepRange,
      temperatureRange,
      sleepRange,
    ].every((range) =>
      [
        "heartRateRange",
        "stepCountRange",
        "temperatureRange",
        "sleepTimeRange",
      ].every((key) => !checkRange(range, key))
    );

    const isSleepTypeEmpty = sleepType === "両方" || sleepType === "";

    return isDateRangeEmpty && isRangeEmpty && isSleepTypeEmpty;
  };

  // setSearchInputs関数を抽出
  const setSearchInputs = () => {
    setSearchInput({
      startHeartInput: true,
      endHeartInput: true,
      startAverageHeartInput: true,
      endAverageHeartInput: true,
      startStepsInput: true,
      endStepsInput: true,
      startAverageStepsInput: true,
      endAverageStepsInput: true,
      startTemperatureInput: true,
      endTemperatureInput: true,
      startAverageTemperatureInput: true,
      endAverageTemperatureInput: true,
      startSleepInput: true,
      endSleepInput: true,
      startAverageSleepInput: true,
      endAverageSleepInput: true,
    });
  };

  // onSearchClick関数をリファクタリング
  const onSearchClick = () => {
    const isInputEmpty = validateInputs();
    setSearchInputs();

    if (isInputEmpty) {
      const params = {
        user,
        dateRangeValue: { start: null, end: null },
        heartRange: heartRange.heartRateRange,
        averageHeartRange: heartRange.averageHeartRateRange,
        stepCountRange: stepRange.stepCountRange,
        averageStepCountRange: stepRange.averageStepCountRange,
        temperatureRange: temperatureRange.temperatureRange,
        averageTemperatureRange: temperatureRange.averageTemperatureRange,
        sleepTimeRange: sleepRange.sleepTimeRange,
        averageSleepTimeRange: sleepRange.averageSleepTimeRange,
        sleepTypeValue: selectedSleepTypeValue,
      };
      setSearchParams(params);
      handleSearch(params);
    } else {
      const params = {
        user,
        dateRangeValue,
        heartRange: heartRange.heartRateRange,
        averageHeartRange: heartRange.averageHeartRateRange,
        stepCountRange: stepRange.stepCountRange,
        averageStepCountRange: stepRange.averageStepCountRange,
        temperatureRange: temperatureRange.temperatureRange,
        averageTemperatureRange: temperatureRange.averageTemperatureRange,
        sleepTimeRange: sleepRange.sleepTimeRange,
        averageSleepTimeRange: sleepRange.averageSleepTimeRange,
        sleepTypeValue: selectedSleepTypeValue,
      };
      setSearchParams(params);
      handleSearch(params);
    }
  };

  // handleDateData関数でdate-fnsを使用
  const handleDateData = (value: [Date | null, Date | null]) => {
    if (value[0] === null || value[1] === null) {
      setDateRangeValue({ start: "", end: "" });
    } else {
      const startDate = toDate(value[0]);
      const endDate = toDate(value[1]);
      setDateRangeValue({
        start: startDate,
        end: endDate,
      });
    }
  };

  // setRange関数を抽出
  const setRange = (
    setter: React.Dispatch<React.SetStateAction<any>>,
    key: string,
    value: { start: string; end: string }
  ) => {
    setter((prevState: any) => ({
      ...prevState,
      [key]: {
        start: value.start,
        end: value.end,
      },
    }));
  };

  return (
    <Panel>
      <div className="search-container__text">
        ✓必要な項目にご入力ください（ブランクの場合は全件が対象となります）
      </div>
      <div className="search-date">
        <Row>
          <Column xs={12} md={6}>
            <SearchDateInput
              isDateRange={isDateValue}
              setDateRange={handleDateData}
            />
            {isDateValue}
          </Column>
          <Column xs={12} md={6}>
            <SearchSelect
              options={userOptions}
              label="氏名"
              placeholder="氏名を選択してください"
              onChange={handleUserNameChange}
            />
          </Column>
        </Row>
      </div>
      <Row>
        {target === "心拍" && (
          <Column xs={12} sm={6} md={6}>
            <SearchBox
              title="心拍"
              label="心拍数 (回/分)"
              rangeValue={heartRange.heartRateRange}
              setRangeValue={(value) =>
                setRange(setHeartRange, "heartRateRange", value)
              }
              labelAverage="平均 (回/期間)"
              rangeAverageValue={heartRange.averageHeartRateRange}
              setRangeAverageValue={(value) =>
                setRange(setHeartRange, "averageHeartRateRange", value)
              }
              styleStart={searchInput.startHeartInput}
              styleEnd={searchInput.endHeartInput}
              styleStartAverage={searchInput.startAverageHeartInput}
              styleEndAverage={searchInput.endAverageHeartInput}
              maxLength={3}
            />
          </Column>
        )}
        {target === '歩数' && (
          <Column xs={12} sm={6} md={6}>
            <SearchBox
              title={"歩数"}
              label={"歩数 (歩/日)"}
              rangeValue={{
                start: stepRange.stepCountRange.start,
                end: stepRange.stepCountRange.end,
              }}
              setRangeValue={(value) =>
                setRange(setStepRange, "stepCountRange", value)
              }
              labelAverage={"累積歩数 (歩)"}
              rangeAverageValue={{
                start: stepRange.averageStepCountRange.start,
                end: stepRange.averageStepCountRange.end,
              }}
              setRangeAverageValue={(value) =>
                setRange(setStepRange, "averageStepCountRange", value)
              }
              styleStart={searchInput.startStepsInput}
              styleEnd={searchInput.endStepsInput}
              styleStartAverage={searchInput.startAverageStepsInput}
              styleEndAverage={searchInput.endAverageStepsInput}
              maxLength={5}
            />
          </Column>
        )}
        {target === '体温' && (
          <Column xs={12} sm={6} md={6}>
            <SearchBox
              title={"体温"}
              label={"体温 (度)"}
              rangeValue={{
                start: temperatureRange.temperatureRange.start,
                end: temperatureRange.temperatureRange.end,
              }}
              setRangeValue={(value) =>
                setRange(setTemperatureRange, "temperatureRange", value)
              }
              labelAverage={"平均 (度/期間)"}
              rangeAverageValue={{
                start: temperatureRange.averageTemperatureRange.start,
                end: temperatureRange.averageTemperatureRange.end,
              }}
              setRangeAverageValue={(value) =>
                setRange(setTemperatureRange, "averageTemperatureRange", value)
              }
              styleStart={searchInput.startTemperatureInput}
              styleEnd={searchInput.endTemperatureInput}
              styleStartAverage={searchInput.startAverageTemperatureInput}
              styleEndAverage={searchInput.endAverageTemperatureInput}
              maxLength={4}
            />
          </Column>
        )}
        {target === '睡眠' && (
          <Column xs={12} sm={6} md={6}>
            <SearchBox
              title={"睡眠"}
              label={"睡眠時間(分)/日"}
              rangeValue={{
                start: sleepRange.sleepTimeRange.start,
                end: sleepRange.sleepTimeRange.end,
              }}
              setRangeValue={(value) =>
                setRange(setSleepRange, "sleepTimeRange", value)
              }
              labelAverage={"平均睡眠時間(分)/期間"}
              rangeAverageValue={{
                start: sleepRange.averageSleepTimeRange.start,
                end: sleepRange.averageSleepTimeRange.end,
              }}
              setRangeAverageValue={(value) =>
                setRange(setSleepRange, "averageSleepTimeRange", value)
              }
              setSelectedSleepType={handleSelectedSleepType}
              sleepTypeOption={searchSleepType}
              selectedSleepTypeValue={selectedSleepTypeValue}
              setSelectedSleepTypeValue={setSelectedSleepTypeValue}
              styleStart={searchInput.startSleepInput}
              styleEnd={searchInput.endSleepInput}
              styleStartAverage={searchInput.startAverageSleepInput}
              styleEndAverage={searchInput.endAverageSleepInput}
              maxLength={4}
            />
          </Column>
        )}
      </Row>
      <div className="search-container__button">
        <ActionBtn
          text="検索する"
          onClick={onSearchClick}
          submit
          color="primary"
        />
      </div>
    </Panel>
  );
};

export default SearchContainer;
