import axios from "axios";
import { loginUrl } from "../../constants";

const login = async (email: string, password: string): Promise<string> => {
	const body = {
		company_admin_user: {
			email,
			password,
		},
	};
	try {
		const res = await axios.post(loginUrl, body);
		return res.headers.authorization;
	} catch (e) {
		throw e;
	}
};

export default login;
