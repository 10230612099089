import * as React from "react";
import {
	FaRegChartBar,
	FaUsers,
	FaGift,
	FaCog,
	FaBullhorn,
	FaCoins,
	FaNotesMedical,
	FaSearch,
	FaHospitalUser,
	FaQrcode,
} from "react-icons/fa";

export interface INavItem {
	id: number;
	text: string;
	route: string;
	color:
		| "primary"
		| "info"
		| "dark"
		| "light"
		| "warning"
		| "danger"
		| "success"
		| "secondary";
	icon: React.ReactElement;
	service:
		| null
		| "リング"
		| "打刻";
}

export const navItems: Array<INavItem> = [
	{
		id: 0,
		text: "ホーム",
		route: "/",
		color: "primary",
		icon: <FaRegChartBar />,
		service: null,
	},
	{
		id: 1,
		text: "社員管理",
		route: "/employees/list",
		color: "success",
		icon: <FaUsers />,
		service: null,
	},
	{
		id: 2,
		text: "商品の利用明細",
		route: "/product/transaction-history",
		color: "warning",
		icon: <FaGift />,
		service: null,
	},
	{
		id: 3,
		text: "社員の利用実績",
		route: "/usage",
		color: "info",
		icon: <FaCoins />,
		service: null,
	},
	{
		id: 4,
		text: "お知らせ配信",
		route: "/announcements",
		color: "primary",
		icon: <FaBullhorn />,
		service: null,
	},
	{
		id: 5,
		text: "健康データ",
		route: "/healthcare",
		color: "primary",
		icon: <FaNotesMedical />,
		service: "リング",
	},
	{
		id: 6,
		text: "健康データ検索",
		route: "/healthcareSearch",
		color: "primary",
		icon: <FaHospitalUser />,
		service: "リング",
	},
	{
		id: 7,
		text: "リング検索",
		route: "/ringSearch",
		color: "primary",
		icon: <FaSearch />,
		service: "リング",
	},
	{
		id: 8,
		text: "打刻",
		route: "/punch",
		color: "secondary",
		icon: <FaQrcode />,
		service: "打刻",
	},
	{
		id: 9,
		text: "設定",
		route: "/settings",
		color: "secondary",
		icon: <FaCog />,
		service: null,
	},
];
